const Analytics = () => (
  <>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-Y97Q9DVE61"
    />

    <script
      dangerouslySetInnerHTML={{
        __html: `      
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-Y97Q9DVE61');`,
      }}
    />
  </>
);

export default Analytics;
